.addressContainer {
  display: flex;
  justify-content: flex-end; /* Align to the right */
  width: 50%; /* Adjust width to 50% */
}

@media screen and (max-width: 768px) {
  .addressContainer {
    width: 100%; /* Adjust width to 100% on smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .addressContainer {
    justify-content: center; /* Align to the center on even smaller screens */
  }
}

/* Additional styles */
.addressContainer {
  width: 100%;
}

/* Style for input fields */
.MuiInputBase-root {
  margin-bottom: 10px;
}

/* Style for input label */
.MuiInputLabel-root {
  color: #fff;
}

/* Style for button */
.MuiButton-contained {
  background-color: #ff9800;
  color: #fff;
  margin-top: 10px;
}

/* Style for button hover */
.MuiButton-contained:hover {
  background-color: #f57c00;
}

/* Add your additional CSS rules here */

.ticker-bar {
  background-color: #25282c; /* Background color */
  color: #fff; /* Text color */
  padding: 10px; /* Adjust padding as needed */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc; /* Optional: Add a border */
}

.ticker-bar h1 {
  margin: 0; /* Remove default margin */
}

.ticker-bar p {
  margin: 0 10px; /* Add some margin around the paragraphs */
}

.dashboard-container {
  display: flex;
}

.left-column {
  flex: 1;
  padding: 20px;
}

.right-column {
  flex: 1;
  padding: 20px;
}

.custom-select {
  background-color: #333;
  color: #fff;
  border-radius: 8px;
  padding: 8px 12px;
  margin-right: 10px;
}

.custom-menu-item {
  display: flex;
  align-items: center;
}

.token-logo {
  height: 20px;
  margin-right: 5px;
}
.positive {
  color: green;
}

.negative {
  color: red;
}